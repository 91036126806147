/* global _ */

$(document).ready(function () {
    // INFO BOX TOGGLE
    $('.info-box .info-box--button').click(function () {
        $(this).siblings('.info-box--container').fadeToggle();
    });

    // CONTACT LINKS
    $('.assist-fields a, .close-project').click(function (e) {
        e.preventDefault();
        var self = $(this);

        $('body').prepend('<div class="loader" style="opacity: 0"></div>');
        $('.loader').animate({opacity: 1}, 500, function () {
            return window.location.href = self.attr('href');
        });
    });

    $('.project-type input[data-type]').click(function () {
        $('div[data-type="' + $(this).attr('data-type') + '"]').toggleClass('hidden');

        $('.row:not(.hidden) .heading-2 b').each(function (index) {
            $(this).html('0' + (index + 1));
        });
    });

    $('#project-page input, #project-page textarea').on('keyup keydown focus blur', function () {
        if ($(this).val() !== '') {
            return $(this).parent().addClass('active');
        }
        return $(this).parent().removeClass('active');
    });

    $('[name="project-form"]').submit(function (e) {
        e.preventDefault();

        $('button[type="submit"]').attr('disabled', 'disabled');

        var data = $(this).serializeArray(), email,
                message = "Zdravo!\n\nDobili ste upit za novu uslugu.\nInformacije su u daljem tekstu:\n\n";

        $.each(data, function (i, item) {
            if (item['name'] === 'Email') {
                email = item['value'];
            }

            if (item['value'] !== '') {
                message += item['name'] + ': ' + item['value'] + "\n";
            }
        });

        $.ajax({
            type: 'POST',
            url: 'https://www.ninepixels.io/np-api/web/mailer/sendmail',
            data: {
                'subject': 'Novi zahtev za uslugu',
                'from': email,
                'to': 'no-reply@ninepixels.io',
                'body': message
            }
        }).done(function (response) {
            $('.info-wrapper, .message.success').fadeIn(800);
            setTimeout(function () {
                $('#project-page a.close-project').click();
            }, 4000);
        });
    });
});